<template>
  <div class="button-group mb-10" :class="[
      $props.background ? 'has-background' : '',
      $props.type === 'group' && $props.background ? 'pt-7 pb-5 px-5' : '',
  ]">
    <Headline v-if="$props.headline" type="h2" :classes="$props.headlineType === 'small' ? 'subline2 mb-5 block text-center text-green_dark' : 'subline1 mb-5 block text-center text-pumpkin_80'" :text="$props.headline" />
    <div class="button-group-container" :class="[
        $props.type === 'group' ? 'button-container inline-flex content-stretch flex-wrap -mx-2' : '',
        $props.type === 'navigation' ? 'navigation-list' : '',
    ]">
      <Buttons v-for="(button, key) in $props.buttons" :key="key" :type="$props.type === 'navigation' ? 'navigation' : 'group'" :href="button.href" :content="button.content" :color="button.color" :icon="!!button.icon" :icon_type="button.icon" :icon_weight="button.icon_weight" />
    </div>
  </div>
</template>

<script>
import {defineAsyncComponent} from "vue";
import Headline from "@/components/Headline.vue";

const Buttons = defineAsyncComponent(() => import("@/components/Buttons"));

export default {
  name: "ButtonGroup",
  props: ["buttons", "type", "background", "headline", "headlineType"],
  components: {
    Headline,
    Buttons
  }
}
</script>

<style lang="scss" scoped>
.button-group {

  &.has-background {
    background: #FAD3C8;
  }
}
</style>